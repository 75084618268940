@use '../../styles' as *;

.loginCard {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 430px;
  min-width: 340px;

  @include smallAndBelowLocal {
    min-width: unset;
  }
}

.closeIcon {
  height: 16px;
  object-fit: contain;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 16px;
}

.header {
  min-width: 250px;
}

.title {
  @include font_h4;

  color: $font_color_b100;
  margin-bottom: 24px;
  text-align: center;
}

.loginDivider {
  @include font_body;

  color: $font_color_b24;
  margin: 16px;
  text-align: center;
}

.toggleText {
  @include font_body2;

  color: $font_color_b64;
  cursor: pointer;
  margin-top: 16px;
  text-align: center;

  span {
    color: $font_color_main;
  }
}

.policyText {
  @include font_footnote;

  color: $font_color_b64;
  cursor: pointer;
  text-align: center;

  a {
    color: $font_color_main;
    font-weight: $heavy;
  }
}
