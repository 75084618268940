$color_main_100: rgba(3, 169, 244, 1);

$color_vivid_blue_100: rgba(2, 207, 242, 1);

$color_white_100: rgba(255, 255, 255, 1);
$color_white_50: rgba(255, 255, 255, 0.5);
$color_white_40: rgba(255, 255, 255, 0.4);
$color_white_30: rgba(255, 255, 255, 0.3);
$color_white_20: rgba(255, 255, 255, 0.2);
$color_white_10: rgba(255, 255, 255, 0.1);

$color_flash_white_100: rgba(238, 242, 243, 1);

$color_ice_white_50: rgba(227, 227, 227, 0.5);

$color_gainsboro_100: rgba(220, 220, 220, 1);

$color_light_gray_100: rgba(189, 189, 189, 1);

$color_dark_gray_100: rgba(169, 169, 169, 1);

$color_spanish_gray_100: rgba(151, 151, 151, 1);

$color_spanish_gray_50: rgba(151, 151, 151, 0.5);

$color_generic_gray_100: rgba(141, 141, 141, 1);

$color_gray_100: rgba(119, 119, 119, 1);

$color_black_100: rgba(0, 0, 0, 1);
$color_black_80: rgba(0, 0, 0, 0.8);
$color_black_70: rgba(0, 0, 0, 0.7);
$color_black_50: rgba(0, 0, 0, 0.5);
$color_black_30: rgba(0, 0, 0, 0.3);
$color_black_25: rgba(0, 0, 0, 0.25);
$color_black_20: rgba(0, 0, 0, 0.2);
$color_black_10: rgba(0, 0, 0, 0.1);

$color_error_100: rgba(229, 59, 59, 1);
$color_error_50: rgba(229, 59, 59, 0.5);
$color_error_30: rgba(229, 59, 59, 0.3);
$color_error_20: rgba(229, 59, 59, 0.2);
$color_error_10: rgba(229, 59, 59, 0.1);

$color_facebook_100: rgba(59, 89, 152, 1);

$color_warning_100: rgb(255, 238, 0);
$color_warning_dark_100: rgb(214, 111, 27);
$color_success_100: rgb(52, 138, 52);
$color_text_general: #2f3237;
$color_border_general: #d3dade;
$color_grey_text_general: #4e545f;
