@use '../../../../styles' as *;

.inner {
  height: 100%;
  position: relative;
}

.closeArea {
  position: absolute;
  right: 20px;
  top: 20px;
}

.modelContet {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 40px 20px;
}

.closeBtn {
  align-items: center;
  background-color: $color_white_100;
  border: 0;
  color: $color_black_70;
  cursor: pointer;
  font-size: 20px;
  right: 15px;
  top: 15px;
  z-index: 10;
  position: absolute;
}

.modalTitle {
  @include font_h4_large;

  color: $font_color_black_grey;
  font-weight: $semibold;
  line-height: normal;
  margin-bottom: 30px;
}

.localList {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 37px;
}

.localItem {
  @include font_cta;

  color: $font_color_black_grey;
  font-weight: $regular;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 6px;
}

.flagWrapper {
  display: flex;
  width: 40px;
  justify-content: center;
}

.flagImage {
  width: auto;
}
