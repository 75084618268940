@use '../../../styles' as *;

.selectItem {
  align-items: center;
  color: $font_color_black_grey;
  display: flex;
  gap: 8px;
  padding: 16px 9px;
}

.selectItemLessPadding {
  padding: 13px 9px;
}

.selectItem:hover {
  background-color: rgba(2, 207, 242, 0.1);
}

.localSwitchSelectItem {
  @include custom_font;

  align-items: center;
  color: $font_color_black_grey_icon;
  display: flex;
  gap: 8px;
  padding: 9px;
}

.localeSwitchSelectItem:hover {
  background-color: rgba(144, 146, 163, 0.3);
}

.selectedItem {
  align-items: center;
  color: $font_color_black_grey;
  display: flex;
  gap: 8px;
  padding: 16px 9px;
}

.localeSwitchSelectedItem {
  @include custom_font;
  @include multilineEllipsis(1);

  align-items: center;
  color: $font_color_black_grey_icon;
  font-size: 14px;
  display: flex;
  gap: 8px;
  padding: 0 10px 0 5px;
}

.selectLabel {
  @include multilineEllipsis(1);
}

.flagWrapper {
  display: flex;
  width: 40px;
  justify-content: center;
}

.localeSwitchSelectedItemFlagWrapper {
  display: flex;
  justify-content: center;
  width: auto;
}

.flagImage {
  width: auto;
}

.selectedItemFlagWrapper {
  display: flex;
  justify-content: center;
}

.mobileSelectWrappr {
  background-color: $color_white_20 !important;
  height: 32px !important;
  border: 1px solid rgb(144 146 163 / 20%) !important;
  border-radius: 23.529px !important;
  padding: 9px 10px !important;
  width: 170px;
  display: flex;
  justify-content: space-between;
}

.mobileSelectedItem {
  @include custom_font;

  align-items: center;
  color: $font_color_black_grey_icon;
  font-size: 14px;
  display: flex;
  gap: 8px;
  padding: 0 15px 0 9px;
}

.mobileSelectContainer {
  background-color: $color_white_100;
  border: 2px solid $color_gainsboro_100;
  border-radius: 4px;
  height: 58px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
