@use '../../../styles' as *;

.inputContainer {
  align-items: center;
  background: $color_flash_white_100;
  border-radius: 30px;
  display: flex;
  min-height: 40px;
  overflow: hidden;
  padding-left: 30px;
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  /* stylelint-disable selector-no-qualifying-type */
  /* Firefox */
  input[type='number'] {
    appearance: textfield;
  }
  /* stylelint-enable selector-no-qualifying-type */
}

.inputContainerBorder {
  align-items: center;
  background: $color_white_100;
  border: 1px solid $color_spanish_gray_100;
  border-radius: 8px;
  display: flex;
  min-height: 48px;
  overflow: hidden;
  padding: 0 12px;
}

.customInput {
  appearance: none;
  background-color: transparent;
  border: 0;
  display: block;
  font-family: $mainFont;
  height: 100%;
  outline: none;
  width: 100%;
}

.inputStyles {
  @include font_body;

}

.errorInput {
  background-color: $color_error_10;
  border-color: $color_error_50 !important;
  color: $color_error_100 !important;
}
