@use '../../../styles' as *;

.input {
  background-color: $color_white_100;
  border: 2px solid $color_gainsboro_100;
  border-radius: 4px;
  height: 58px;
  padding: 10px;

  &.borderNone {
    border: 0 !important;
    border-bottom: 1px solid $color_gainsboro_100 !important;
    border-radius: 0 !important;
  }

  &:focus,
  &:focus-within {
    border-color: $color_main_100 !important;
    box-shadow: none !important;
  }

  &.error {
    border-color: $color_error_100 !important;
  }

  &.error:focus,
  &.error:focus-within {
    border-color: $color_error_100 !important;
  }
}

.errorMsg {
  @include font_footnote;
  color: $color_error_100;
  display: block;
  height: 16px;
}
