@use '../../../styles' as *;

.selectWrappr {
  background-color: $color_white_20 !important;
  height: 32px !important;
  border: 1px solid rgb(144 146 163 / 20%) !important;
  border-radius: 23.529px !important;
  padding: 6px 10px !important;
  width: 170px !important;
}
