@use '../../../styles' as *;

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.input {
  @include font_body;

  color: $font_color_b100;
}

.forgotPasswordText {
  @include font_body;

  color: $font_color_b64;
  cursor: pointer;
  display: block;
  margin-top: 16px;
  text-align: center;
}
