@use '../../../styles' as *;

.auraBtn {
  @include font_body;

  align-items: center;
  background: linear-gradient(180deg, #48f2f4, #04aaf4 74%);
  border: 0;
  border-radius: 27px;
  color: $font_color_w100;
  cursor: pointer;
  display: flex;
  font-weight: $regular;
  height: 54px;
  justify-content: center;
  line-height: normal;
  min-width: 180px;
  padding: 0 32px;
  text-align: center;
  white-space: nowrap;

  &:disabled {
    background: none;
    background-color: $color_gray_100;
    cursor: not-allowed;
  }
}

.horizontalGradient {
  background: linear-gradient(277.58deg, #4ec8ff 5.87%, #1df4ed 94.13%);
}

.btnShadow {
  box-shadow: 0 0 14px 0 #48f2f4;
}

.btnIcon {
  line-height: 1;
  margin-right: 9px;
}

.subDescription {
  @include font_footnote;
  font-weight: $light;
  line-height: normal;
  margin: 0;
}
